












































































































































































import Vue, { PropType } from 'vue';
import { ProgressTrackerModes } from '@/scripts/store/modules/home/types';

export interface RadarChartAxis {
  color: String;
  value: Number;
  label: String;
  assessmentLabel: String;
}

export default Vue.extend({
  name: 'checkup-results-radarchart',
  props: {
    values: Object,
    mode: {
      type: String as PropType<ProgressTrackerModes>,
      default: 'completed',
    },
    canvasWidth: {
      type: String,
      default: '60%',
    },
    showDrilldown: {
      type: Boolean,
      default: false,
    },
    showLabels: {
      type: Boolean,
      default: true,
    },
    showAnimation: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      modes: ProgressTrackerModes,
      ENERGY: null as RadarChartAxis | null,
      GAD7: null as RadarChartAxis | null,
      PHQ9: null as RadarChartAxis | null,
      STRESS: null as RadarChartAxis | null,
      'CD-RISC2': null as RadarChartAxis | null,
    };
  },
  watch: {
    values: {
      immediate: true,
      deep: true,
      handler(newValues) {
        this.updateValues(newValues);
      },
    },
    showDrilldown: {
      handler(newValue) {
        this.updateShowDrilldown(newValue);
      },
    },
  },
  mounted() {
    this.updateShowDrilldown(this.showDrilldown);
  },
  methods: {
    updateValues(newValues: any) {
      [ 'ENERGY', 'STRESS', 'PHQ9', 'GAD7', 'CD-RISC2' ].forEach(assessment => {
        const found = newValues[assessment];
        const corresponding: any = this.$t('app.assessments.' + assessment);
        const scaleItem = (this.$t('app.assessments.simplifiedScoreScale') as any).find((e: any) => e.name === found.simplifiedValue);
        if (found) {
          this.$data[assessment] = {
            assessmentLabel: corresponding.label,
            color: corresponding.color,
            ...scaleItem,
          };
        } else {
          this.$data[assessment] = null;
        }
      });
    },
    updateShowDrilldown(newValue: any) {
      if (this.$refs.graph) {
        (this.$refs.graph as HTMLElement).querySelectorAll('.label, .very-low, .low, .moderate, .high').forEach((el: Element) => {
          (el as HTMLElement).style.cursor = newValue ? 'pointer' : 'inherit';
        });
      }
    },
    onSelect(assessment: any) {
      this.$emit('selected-assessment', assessment);
    },
  },
});
