export enum TherapyTypeEnum {
  /* eslint-disable no-unused-vars */
  ASSESSMENT = 'assessment',
  CAROUSEL = 'carousel',
  CELEBRATION = 'celebration',
  COMPOSITION = 'composition',
  CU_SCHEDULER = 'cu_scheduler',
  DECISION = 'decision',
  ACCORDION = 'accordion',
  GOAL = 'goal',
  HTML = 'html',
  MCQ = 'mcq',
  MSQ = 'msq',
  MOOD = 'mood',
  NEXT_STEP = 'next_step',
  POLL = 'poll',
  POPUP = 'popup',
  REDIRECTION = 'redirection',
  SLIDER = 'slider',
  SUBMENU = 'submenu',
  THOUGHTS = 'thoughts',
  VIDEO = 'video',
  WORKBOOK = 'workbook',
  /* eslint-enable no-unused-vars */
}

export const BranchingTypes: TherapyTypeEnum[] = [
  TherapyTypeEnum.DECISION,
  TherapyTypeEnum.NEXT_STEP,
  TherapyTypeEnum.SUBMENU,
];

export const OutputTypes: TherapyTypeEnum[] = [
  TherapyTypeEnum.ACCORDION,
  TherapyTypeEnum.CAROUSEL,
  TherapyTypeEnum.COMPOSITION,
  TherapyTypeEnum.HTML,
  TherapyTypeEnum.POPUP,
  TherapyTypeEnum.VIDEO,
];

export enum StyleTypeEnum {
  /* eslint-disable no-unused-vars */
  ACTION_LABEL = 'actionlabel',
  ANIMATION = 'animation',
  ASSESSMENT_CHART = 'assessmentchart',
  AUDIO ='audio',
  BACKGROUND = 'background',
  BODY = 'body',
  CHECKUP_RESULT_CHART = 'checkupresultchart',
  CITATION = 'citation',
  CONTENT = 'content',
  DOWNLOAD = 'download',
  DOWNLOAD_REPORT = 'download-report',
  EXPLANATORY = 'explanatory',
  GRAPHIC = 'graphic',
  GUIDE = 'guide',
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  H4 = 'h4',
  H5 = 'h5',
  HYPERLINK = 'hyperlink',
  INSTRUCTIONAL = 'instructional',
  MJ = 'mj',
  MM = 'mm',
  MOOD = 'mood',
  NAVIGATION = 'navigation',
  PLACEHOLDER = 'placeholder',
  SESSIONS = 'sessions',
  STEP_NUMBER = 'stepnumber',
  TEXT = 'text',
  THUMBNAIL = 'thumbnail',
  VIDEO = 'video',
  WEBINAR_REGISTRATION = 'webinarregistration',
  /* eslint-enable no-unused-vars */
}

export enum StyleSizeEnum {
  /* eslint-disable no-unused-vars */
  XS = 'xs',
  SM = 'sm',
  MD = 'md',
  LG = 'lg',
  XL = 'xl',
  /* eslint-enable no-unused-vars */
}

export enum StyleHorizontalEnum {
  /* eslint-disable no-unused-vars */
  LEFT = 'left',
  CENTER = 'center',
  RIGHT = 'right',
  /* eslint-enable no-unused-vars */
}

export enum GraphicRenderEnum {
  /* eslint-disable no-unused-vars */
  INLINE = 'inline',
  IMAGE = 'image',
  /* eslint-enable no-unused-vars */
}

export enum StyleVerticalEnum {
  /* eslint-disable no-unused-vars */
  TOP = 'top',
  MIDDLE = 'middle',
  BOTTOM = 'bottom',
  /* eslint-enable no-unused-vars */
}

export enum StyleDeviceEnum {
  /* eslint-disable no-unused-vars */
  DESKTOP = 'desktop',
  MOBILE = 'mobile',
  /* eslint-enable no-unused-vars */
}

export enum StylePeriodEnum {
  /* eslint-disable no-unused-vars */
  DAILY = 'daily',
  HISTORY = 'history',
  /* eslint-enable no-unused-vars */
}

export enum StyleAssessmentEnum {
  /* eslint-disable no-unused-vars */
  GAD7 = 'gad7',
  PHQ9 = 'phq9',
  STRESS = 'stress',
  ENERGY = 'energy',
  /* eslint-enable no-unused-vars */
}

export enum TherapyStatusEnum {
  /* eslint-disable no-unused-vars */
  INITIAL = 'INITIAL',
  STARTED = 'STARTED',
  COMPLETED = 'COMPLETED',
  RECOMMENDED = 'RECOMMENDED',
  /* eslint-enable no-unused-vars */
}

export interface IStyleContent {
  key: StyleTypeEnum,
  content: string,

  size?: StyleSizeEnum,
  horizontal?: StyleHorizontalEnum,
  vertical?: StyleVerticalEnum,
  device?: StyleDeviceEnum,
  period?: StylePeriodEnum,
  assessmentName?: StyleAssessmentEnum,
  uen?: string,
  path?: string,
  id?: string,
  automatic?: boolean,
  color?: string,
  additionalclasses?: string,
  card?: boolean,
  recommended?: boolean,
}

export interface IStyleContentAnimation extends IStyleContent {
  autoplay?: boolean,
  bounce?: boolean,
  hover?: boolean,
  revert?: boolean,
  speed?: number | string,
  times?: number | string,
  delay?: number,
}

export interface IStyleContentCUScheduler extends IStyleContent {
  weeks?: number | string,
}

export interface IStyleContentGoal extends IStyleContent {
  fields?: string,
  date?: string,
  time?: string,
  reminder?: number | string,
  repeat?: string,
}

export interface IStyleContentGuide extends IStyleContent {
  icon?: string,
  expanded?: boolean,
}

export interface IStyleContentJournal extends IStyleContent {
  fields?: string,
  date?: string,
}

export interface IStyleContentMood extends IStyleContent {
  moods?: string,
  fields?: string,
  date?: string,
  time?: string,
}

export interface IStyleContentNavigation extends IStyleContent {
  label?: string,
}

export interface IStyleContentStepNumber extends IStyleContent {
  step?: number | string,
  of?: number | string,
}

export interface IStyleContentThumbnail extends IStyleContent {
  vimeoid?: string,
}

export interface IStyleContentVideo extends IStyleContent {
  id: string,
}

export interface IStyleContentGraphic extends IStyleContent {
  render?: GraphicRenderEnum,
}

export interface ITherapySession {
  uen: string,
  title: string,
  status: TherapyStatusEnum,
  order: number,
}

export interface ITherapyChoice {
  id: number,
  selected: boolean,
  content: string,
  styleContent: Array<IStyleContent>,
}

export interface ITherapyChoiceAccordion extends ITherapyChoice {
}

export interface ITherapyChoiceCarousel extends ITherapyChoice {
  feedback?: string,
  feedbackStyleContent?: Array<IStyleContent>,
}

export interface ITherapyChoiceDecision extends ITherapyChoice {
}

export interface ITherapyChoiceMCQ extends ITherapyChoice {
  feedback?: string,
  feedbackStyleContent?: Array<IStyleContent>,
}

export interface ITherapyChoiceMSQ extends ITherapyChoice {
  feedback?: string,
  feedbackStyleContent?: Array<IStyleContent>,
}

export interface ITherapyChoicePoll extends ITherapyChoice {
  feedback?: string,
  feedbackStyleContent?: Array<IStyleContent>,
  votesCount: number,
}

export interface ITherapyChoicePopup extends ITherapyChoice {
}

export interface ITherapyChoiceSlider extends ITherapyChoice {
  feedback?: string,
  feedbackStyleContent?: Array<IStyleContent>,
}

export interface ITherapyChoiceSubmenu extends ITherapyChoice {
  locked: boolean,
  initial: boolean,
  started: boolean,
  completed: boolean,
}

export interface ITherapyContent {
  type: TherapyTypeEnum,
  uen: string,
  title?: string,
  content?: string,
  allowComments: boolean,
  allowSkip: boolean,
  allowBack: boolean,
  commentsCount?: number,
  styleContent?: Array<IStyleContent>,
  answered: boolean,
  displayCondition?: string,
  visible?: boolean,
}

export interface ITherapyContentWithChoices extends ITherapyContent {
  choices: Array<ITherapyChoice>,
}

export interface ITherapyContentAccordion extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceAccordion>,
}

export interface ITherapyContentAssessment extends ITherapyContent {
  userAssessmentId?: number,
  assessmentName: string,
}

export interface ITherapyContentCarousel extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceCarousel>,
}

export interface ITherapyContentCelebration extends ITherapyContent {
}

export interface ITherapyContentComposition extends ITherapyContent {
}

export interface ITherapyContentCUScheduler extends ITherapyContent {
  interval?: number,
}

export interface ITherapyContentDecision extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceDecision>,
}

export interface ITherapyContentGoal extends ITherapyContent {
  userGoalId?: number,
}

export interface ITherapyContentHtml extends ITherapyContent {
}

export interface ITherapyContentMCQ extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceMCQ>,
}

export interface ITherapyContentMSQ extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceMSQ>,
}

export interface ITherapyContentMood extends ITherapyContent {
  userMoodId?: number,
}

export interface ITherapyContentPoll extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoicePoll>,
}

export interface ITherapyContentPopup extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoicePopup>,
}

export interface ITherapyContentRedirection extends ITherapyContent {
  redirection: string,
}

export interface ITherapyContentSlider extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceSlider>,
}

export interface ITherapyContentSubmenu extends ITherapyContentWithChoices {
  choices: Array<ITherapyChoiceSubmenu>,
}

export interface ITherapyContentThoughts extends ITherapyContent {
  userThoughtsId?: number,
}

export interface ITherapyContentVideo extends ITherapyContent {
  identifier: string,
  url: string,
  transcript?: string,
}

export interface ITherapyContentWorkbook extends ITherapyContent {
  userAnswer?: string,
}

export interface ITherapyContentGroup {
  group: Array<ITherapyContent>,
  status?: TherapyStatusEnum,
  sessionUen?: string,
}

export interface ITherapyImage {
  id: number,
  name: string,
  description: string,
  image: string,
  altText: string,
}

export interface ITherapyVideo {
  id: string,
  identifier: string,
  vimeoId: string,
  title: string,
  description: string,
  transcript: string
}

export interface TherapyContentState {
  therapyContentGroup?: ITherapyContentGroup,
  nextStepSessionGroup?: ITherapyContentGroup,
  backNotificationAck?: boolean,
  celebrationPopup?: ITherapyContentCelebration,
}

export interface IStyleContentAudio extends IStyleContent {
  id: string
  url: string,
  title: string,
  transcript: string,
}

export interface ITherapyContentJournal extends ITherapyContent {
  userJournalId?: number,
  userAnswer?: string,
}
export interface ITherapyContentNote extends ITherapyContent {
  userNoteId?: number,
  userAnswer?: string,
}
