<template>
  <canvas style="min-height:400px;" ref="graph"></canvas>
</template>

<script>
import Chart from 'chart.js';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import moment from 'moment';

export default {
  name: 'assessmentHistoryChart',
  props: {
    dataset: {
      type: Object,
      required: true,
    },
    scaleDefinition: {
      type: Object,
      required: true,
    },
    clickablePoints: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  data() {
    return {
      key: 0,
      chartRef: false,
    };
  },
  mounted() {
    if (this.dataset && this.scaleDefinition) {
      this.renderChart(this.dataset, this.scaleDefinition, false);
    }
  },
  methods: {
    renderChart(dataset, scaleDefinition, force) {
      if (!dataset) {
        if (this.chartRef) {
          this.chartRef.destroy();
        }
        return;
      }
      Chart.defaults.global.defaultFontSize = 10;
      Chart.defaults.global.defaultFontFamily = 'Open Sans,Lato,Roboto,Noto Sans,-apple-system,BlinkMacSystemFont,sans-serif';

      if (this.chartRef && this.chartRef.data && this.chartRef.data.datasets && this.chartRef.data.datasets.length > 0 && this.chartRef.data.datasets[0].name === dataset.name && !force) {
        return;
      }

      const that = this;
      const chartConfig = {
        type: 'line',
        options: {
          animation: { duration: 0 },
          responsive: true,
          maintainAspectRatio: false,
          borderDash: 0,
          spanGaps: true,
          tooltips: { enabled: false },
          layout: {
            padding: {
              left: 10,
              right: 30,
              top: 30,
              bottom: 0,
            },
          },
          scales: {
            xAxes: [ {
              type: 'time',
              time: { unit: 'day' },
              ticks: {
                source: 'auto',
              },
              scaleLabel: {
                display: true,
                labelString: this.$t('app.products.assessment_history_chart.chart.label.xAxis'),
              },
              gridLines: {
                display: false,
              },
            } ],
            yAxes: [ {
              ticks: {
                beginAtZero: true,
                padding: 2,
                min: scaleDefinition.min,
                max: scaleDefinition.max,
                stepSize: scaleDefinition.stepSize || 1,
              },
              gridLines: {
                display: true,
              },
            } ],
          },
          legend: {
            display: false,
          },
          plugins: {
            datalabels: {
              listeners: {
                click: (context) => {
                  if (!this.clickablePoints) return true;

                  const date = moment(context.dataset.data[context.dataIndex].assessment.completed);
                  const points = context.dataset.data.filter(point => moment(point.assessment.completed).isSame(date, 'day'));

                  this.$emit('clicked-points', points);
                  setTimeout(() => {
                    that.renderChart(this.dataset, this.scaleDefinition, true);
                  }, 50);
                  return true;
                },
                enter: (context) => {
                  if (!this.clickablePoints) return true;
                  context.chart.canvas.style.cursor = 'pointer';
                  return true;
                },
                leave: (context) => {
                  if (!this.clickablePoints) return true;

                  context.chart.canvas.style.cursor = 'default';
                  return true;
                },
              },
              formatter: (value, context) => {
                return value.y;
              },
              backgroundColor(context) {
                return 'white';
              },
              borderColor(context) {
                return context.dataset.borderColor;
              },
              borderWidth(context) {
                return context.dataset.data[context.dataIndex].selected ? 8 : (context.dataset.data[context.dataIndex].assessment.notes && context.dataset.data[context.dataIndex].assessment.notes.length ? 3 : 1);
              },
              font(context) {
                return context.dataset.data[context.dataIndex].selected ? { weight: 'bold', size: 18 } : { weight: 'normal', size: 12 };
              },
              padding(context) {
                const index = context.dataIndex;
                const value = context.dataset.data[index].y;
                if (context.dataset.data[context.dataIndex].selected) {
                  return value < 10 ? { top: 8, bottom: 8, left: 13, right: 13 } : 8;
                }
                return value < 10 ? { top: 5, bottom: 5, left: 8, right: 8 } : 5;
              },
              borderRadius: 50,
              offset: 8,
              textAlign: 'center',
            },
          },
        },
        data: {
          datasets: [ dataset ],
        },
      };

      if (this.chartRef) {
        this.chartRef.destroy();
      }

      this.chartRef = new Chart(this.$refs.graph, {
        type: chartConfig.type,
        data: chartConfig.data,
        options: chartConfig.options,
        plugins: [ ChartDataLabels ],
      });
      this.key++;
    },
  },
};
</script>
