import { render, staticRenderFns } from "./element-part-guide.vue?vue&type=template&id=488779c0&"
import script from "./element-part-guide.vue?vue&type=script&lang=ts&"
export * from "./element-part-guide.vue?vue&type=script&lang=ts&"
import style0 from "./element-part-guide.vue?vue&type=style&index=0&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* vuetify-loader */
import installComponents from "!../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
installComponents(component, {VFlex,VIcon,VLayout})


/* hot reload */
if (module.hot) {
  var api = require("/opt/jenkins/workspace/starling-app-dev-ci/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('488779c0')) {
      api.createRecord('488779c0', component.options)
    } else {
      api.reload('488779c0', component.options)
    }
    module.hot.accept("./element-part-guide.vue?vue&type=template&id=488779c0&", function () {
      api.rerender('488779c0', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/products/components/elements-parts/element-part-guide.vue"
export default component.exports