var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-layout",
    { staticClass: "element-part-guide" },
    [
      _c("v-flex", { attrs: { xs12: "" } }, [
        _c("div", { staticClass: "guide-icon" }, [
          _c(
            "svg",
            { attrs: { "aria-hidden": "true", width: "100%", height: "100%" } },
            [_c("use", { attrs: { "xlink:href": "#" + _vm.iconName } })]
          )
        ]),
        _c(
          "div",
          {
            staticClass: "guide-box",
            class: [
              _vm.boxColor,
              {
                expanded: !_vm.part.expanded && _vm.readMore,
                collapsed: !_vm.part.expanded && !_vm.readMore
              }
            ]
          },
          [
            _c(
              "p",
              { staticClass: "guide-text starling-explanatory" },
              [_c("sm-markup-text", { attrs: { value: _vm.part.content } })],
              1
            )
          ]
        ),
        !_vm.part.expanded
          ? _c(
              "div",
              { staticClass: "guide-button" },
              [
                _c(
                  "primary-button",
                  {
                    staticClass: "ma-0 pl-3 font-weight-regular",
                    attrs: { round: "", small: "" },
                    on: { click: _vm.toggleReadMore }
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t(
                            "app.products.parts.guide." +
                              (_vm.readMore ? "read_less" : "read_more")
                          )
                        ) +
                        "\n        "
                    ),
                    _vm.readMore
                      ? _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { right: "" } },
                          [_vm._v("expand_less")]
                        )
                      : _c(
                          "v-icon",
                          { staticClass: "ml-2", attrs: { right: "" } },
                          [_vm._v("expand_more")]
                        )
                  ],
                  1
                )
              ],
              1
            )
          : _vm._e()
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }