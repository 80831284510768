






























import Vue from 'vue';
import { IStyleContentGuide } from '@/scripts/store/modules/therapy/types';
import PrimaryButton from '@/views/components/button/primary-button.vue';

export default Vue.extend({
  name: 'element-part-guide',
  components: { PrimaryButton },
  props: {
    part: {
      type: Object as () => IStyleContentGuide,
      required: true,
    },
  },
  data() {
    return {
      readMore: false,
    };
  },
  computed: {
    iconName(): string {
      return this.part.icon || 'mini-miki';
    },
    boxColor(): string {
      if (this.part.color) {
        return `${this.part.color}-box`;
      }
      return this.iconName === 'mini-bird' ? 'blue-box' : 'red-box';
    },
  },
  mounted() {
  },
  methods: {
    toggleReadMore() {
      this.readMore = !this.readMore;
    },
  },
});
