var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-container",
    { attrs: { fluid: "", "pa-0": "" } },
    [
      _vm.loading
        ? [
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs12: "" } },
                  [
                    _c("v-progress-circular", {
                      attrs: { color: "primary", indeterminate: "" }
                    })
                  ],
                  1
                )
              ],
              1
            )
          ]
        : _vm.scales && _vm.datasets && _vm.datasets.length > 0
        ? [
            _vm.showSelectedTitle
              ? _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "" } },
                  [
                    _c("v-flex", { attrs: { xs12: "" } }, [
                      _c(
                        "h3",
                        {
                          staticClass: "starling-h3",
                          style: "color:" + _vm.selectedDataset.color
                        },
                        [_vm._v(_vm._s(_vm.selectedDataset.label))]
                      )
                    ])
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "v-layout",
              { attrs: { row: "", wrap: "" } },
              [
                _c(
                  "v-flex",
                  { attrs: { xs2: "" } },
                  [
                    _c("assessment-history-scale", {
                      key: _vm.key,
                      attrs: {
                        "scale-definition":
                          _vm.scales[_vm.selectedDataset.name],
                        min: 0,
                        max: 10
                      }
                    })
                  ],
                  1
                ),
                _c(
                  "v-flex",
                  { attrs: { xs10: "" } },
                  [
                    _c(
                      "v-layout",
                      {
                        attrs: {
                          row: "",
                          wrap: "",
                          "justify-center": "",
                          "align-center": ""
                        }
                      },
                      [
                        _c(
                          "v-flex",
                          { attrs: { xs12: "" } },
                          [
                            _vm.selectedDataset
                              ? _c("assessment-history-chart", {
                                  key: _vm.key,
                                  attrs: {
                                    dataset: _vm.selectedDataset,
                                    "scale-definition":
                                      _vm.scales[_vm.selectedDataset.name],
                                    "clickable-points": _vm.selectedDay == null
                                  },
                                  on: { "clicked-points": _vm.onClickedPoints }
                                })
                              : _vm._e()
                          ],
                          1
                        )
                      ],
                      1
                    )
                  ],
                  1
                )
              ],
              1
            ),
            _vm.scales && _vm.datasets && _vm.datasets.length > 1
              ? _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "", "pt-3": "" } },
                  _vm._l(_vm.datasets, function(dataset) {
                    return _c(
                      "v-chip",
                      {
                        key: dataset.name,
                        staticClass:
                          "assessment-history-chip static-chip selectable font-weight-bold",
                        class: {
                          "mx-2": _vm.$vuetify.breakpoint.mdAndUp,
                          "mx-0": _vm.$vuetify.breakpoint.smAndDown
                        },
                        staticStyle: { "box-shadow": "none" },
                        attrs: {
                          color:
                            _vm.selectedDataset.name === dataset.name
                              ? dataset.color
                              : "white",
                          "text-color":
                            _vm.selectedDataset.name === dataset.name
                              ? "white"
                              : dataset.color
                        },
                        on: {
                          click: function(e) {
                            return _vm.select(dataset)
                          }
                        }
                      },
                      [
                        _vm._v(
                          "\n        " + _vm._s(dataset.label) + "\n      "
                        )
                      ]
                    )
                  }),
                  1
                )
              : _vm._e(),
            _vm.selectedPoints.length > 0
              ? _c(
                  "v-layout",
                  { attrs: { row: "", wrap: "", "justify-center": "" } },
                  _vm._l(_vm.selectedPoints, function(point, index) {
                    return _c(
                      "v-flex",
                      {
                        key: point.assessment.assessmentId + "-" + index,
                        attrs: { xs12: "", "pt-3": "" }
                      },
                      [
                        _c("assessment-notes-display", {
                          attrs: {
                            "user-assessment": point.assessment,
                            "scale-definition":
                              _vm.scales[_vm.selectedDataset.name]
                          },
                          on: {
                            updated: function($event) {
                              return _vm.onUpdatedAssessment(point, $event)
                            }
                          }
                        })
                      ],
                      1
                    )
                  }),
                  1
                )
              : _vm._e()
          ]
        : _vm._e()
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }