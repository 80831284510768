<template>
  <v-container fluid pa-0>
    <template v-if="loading">
      <v-layout row wrap>
        <v-flex xs12>
          <v-progress-circular color="primary" indeterminate></v-progress-circular>
        </v-flex>
      </v-layout>
    </template>
    <template v-else-if="scales && datasets && datasets.length > 0">
      <v-layout row wrap v-if="showSelectedTitle">
        <v-flex xs12>
          <h3 class="starling-h3" :style="`color:${selectedDataset.color}`">{{ selectedDataset.label }}</h3>
        </v-flex>
      </v-layout>
      <v-layout row wrap>
        <v-flex xs2>
          <assessment-history-scale
              :key="key"
              :scale-definition="scales[selectedDataset.name]"
              :min="0"
              :max="10"/>
        </v-flex>
        <v-flex xs10>
          <v-layout row wrap justify-center align-center>
            <v-flex xs12>
              <assessment-history-chart v-if="selectedDataset"
                                        :dataset="selectedDataset"
                                        :scale-definition="scales[selectedDataset.name]"
                                        :key="key"
                                        :clickable-points="selectedDay == null"
                                        @clicked-points="onClickedPoints"/>
            </v-flex>
          </v-layout>
        </v-flex>
      </v-layout>

      <v-layout row wrap pt-3 v-if="scales && datasets && datasets.length > 1">
        <v-chip
            v-for="dataset in datasets"
            :key="dataset.name"
            :color="selectedDataset.name === dataset.name ? dataset.color : 'white'"
            :text-color="selectedDataset.name === dataset.name ? 'white' : dataset.color"
            style="box-shadow:none;"
            class="assessment-history-chip static-chip selectable font-weight-bold"
            :class="{'mx-2':$vuetify.breakpoint.mdAndUp,'mx-0':$vuetify.breakpoint.smAndDown}"
            @click="(e) => select(dataset)">
          {{ dataset.label }}
        </v-chip>
      </v-layout>

      <v-layout row wrap justify-center v-if="selectedPoints.length > 0">
        <v-flex xs12 pt-3 v-for="(point, index ) in selectedPoints" :key="point.assessment.assessmentId +'-' + index">
          <assessment-notes-display :user-assessment="point.assessment"
                                    :scale-definition="scales[selectedDataset.name]"
                                    @updated="onUpdatedAssessment(point, $event)"/>
        </v-flex>
      </v-layout>
    </template>
  </v-container>
</template>

<script>
import moment from 'moment/moment';
import AssessmentHistoryChart from '@/views/home/components/assessment-history-chart.vue';
import AssessmentHistoryScale from '@/views/home/components/assessment-history-scale';
import AssessmentNotesDisplay from '@/views/home/components/assessment-notes-display';
import { CORE_ASSESSMENT_NAMES } from '@/scripts/store/modules/assessments/types';

export default {
  name: 'assessment-history-wrapper',
  components: {
    AssessmentHistoryScale,
    AssessmentHistoryChart,
    AssessmentNotesDisplay,
  },
  props: {
    part: {
      type: Object,
      required: true,
    },
    showSelectedTitle: {
      type: Boolean,
      required: false,
      default: false,
    },
    selectedDay: {
      type: String,
      required: false,
      default: null,
    },
    scaleName: {
      type: String,
      required: false,
      default: 'SIMPLIFIED',
    },
  },
  data() {
    return {
      key: 0,
      scales: null,
      datasets: [],
      selectedDataset: null,
      selectedPoints: [],
      loading: null,
    };
  },
  computed: {
    assessmentName() {
      return this.part && this.part.assessmentName ? this.part.assessmentName.toUpperCase() : null;
    },
  },
  mounted() {
    this.loading = true;

    this.$store
      .dispatch('assessment/getAssessmentHistoryChart', this.assessmentName)
      .then((res) => {
        const sorted = res.sort((a, b) => new Date(a.completed) - new Date(b.completed));
        const holder = {};

        sorted.forEach(element => {
          const point = {};
          point.assessment = { ...element };
          point.selected = false;
          point.x = moment(element.completed).startOf('day').toDate();
          point.y = element.scores.INTRINSIC.value;
          if ((this.assessmentName && this.assessmentName === element.assessmentName) || CORE_ASSESSMENT_NAMES.includes(element.assessmentName)) {
            if (!holder[element.assessmentName]) {
              holder[element.assessmentName] = [];
            }
            holder[element.assessmentName].push(point);
          }
        });

        const scaleHolder = {};
        Promise.all(
          Object.keys(holder).map(assessmentName => {
            return this.$store.dispatch('assessment/getAssessmentScale', {
              assessmentName,
              scaleName: this.scaleName,
            });
          }),
        ).then(res => {
          res.forEach(scale => {
            scaleHolder[scale.assessmentName] = scale;
          });
          this.scales = scaleHolder;
          this.key++;
        });

        const datasets = [];
        Object.keys(holder)
          .forEach(assessmentName => {
            const assessmentConfig = this.$t('app.assessments.' + assessmentName);
            datasets.push({
              name: assessmentConfig.name,
              label: assessmentConfig.label,
              data: holder[assessmentName],
              backgroundColor: 'rgba(0, 0, 0, 0)',
              borderColor: assessmentConfig.color,
              color: assessmentConfig.color,
              pointBackgroundColor: assessmentConfig.color,
              pointRadius: 3,
              pointHoverRadius: 6,
              lineTension: 0,
              cubicInterpolationMode: 'monotone',
              definition: assessmentConfig.definition,
            });
          });
        this.select(datasets[0]);
        this.datasets = datasets;
        this.loading = false;
      })
      .finally(() => {
        this.loading = false;
      });
  },
  methods: {
    select(dataset) {
      this.$log.info('select', dataset);
      this.selectedDataset = dataset;

      // Find last point
      this.selectedPoints = [];
      if (dataset && dataset.data && dataset.data.length) {
        const selectedDay = this.selectedDay ? moment(this.selectedDay) : moment(dataset.data[dataset.data.length - 1].assessment.completed);

        for (const point of dataset.data) {
          if (moment(point.assessment.completed).isSame(selectedDay, 'day')) {
            point.selected = true;
            this.selectedPoints.unshift(point);
          }
        }
      }
      this.key++;
    },
    onUpdatedAssessment(point, userAssessment) {
      this.$log.info('onUpdatedAssessment', point, userAssessment);
      point.assessment = userAssessment;
    },
    onClickedPoints(points) {
      this.$log.info('onClickedPoints', points);

      for (const point of points) {
        point.selected = !point.selected;
        const foundIndex = this.selectedPoints.findIndex(e => e.assessment.id + e.assessment.completed === point.assessment.id + point.assessment.completed);
        if (point.selected) {
          if (foundIndex <= 0) {
            this.selectedPoints.unshift(point);
          }
        } else {
          if (foundIndex >= 0) {
            this.selectedPoints.splice(foundIndex, 1);
          }
        }
      }
    },
  },
};
</script>
<style lang="scss">
.assessment-history-chip {

  @media (max-width: 600px) {
    .v-chip__content {
      padding: 0 6px;
    }
  }
}
</style>
